// ------------------------------------------------------------------------------------------------
// 3rd Party Modules
// ------------------------------------------------------------------------------------------------
import React from 'react';
// ------------------------------------------------------------------------------------------------
// Custom Modules
// ------------------------------------------------------------------------------------------------
import { seoContext } from 'context/seo-context';
import Layout from 'components/Layout';
import './404.scss';
// ------------------------------------------------------------------------------------------------

const FourOhFour: React.FC = () => {
  return (
    <seoContext.Provider value={{ title: 'Contact' }}>
      <Layout>
        <article>
          <div className="inner">
            <div className="section section-gray">
              <div className="section-head">
                <div className="container">
                  <h1 className="four-oh-four__title">404</h1>
                  <div className="four-oh-four__message">The page you were looking for doesn&apos;t exist =(</div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    </seoContext.Provider>
  );
};

export default FourOhFour;
